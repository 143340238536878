import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesoutbounditem/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesoutbounditem/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/salesoutbounditem/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesoutbounditem/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    list(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/list', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getmax(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/getmax', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/updateOrderItem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    warehouselist(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/warehouselist', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    outbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/outbound', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getqty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/getqty', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    receiveitem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/receiveitem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    abortSearch(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/salesoutbounditem/abortSearch', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
